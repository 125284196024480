import React from 'react';
import { Form } from 'react-bootstrap';

export class DriveControl extends React.Component{
    constructor(props){
        super(props)
        this.state = {Items:[], loading:false}
    }
 

    componentDidMount(){
        this.setState({loading:true})
        fetch(this.props.request, {
            headers: this.props.headers
        })
        .then(response => response.json())
        .then(data => {
            this.setState({Items:data, loading:false})
        } )
        .catch(function (error) {
            console.log(error)
        })
    }

    render() {
 
        return (
            this.state.loading ? <div>Items is loading...</div> :
            <Form.Group className='form-row'>
                
            <Form.Label className='col-sm-2' style={{ display: 'flex' }}>{this.props.label} :
            </Form.Label>
            <Form.Control className='col-sm-10' as="select" name={this.props.name} defaultValue={this.props.id} readOnly={this.props.readOnly} >
                {
                    this.state.Items.map(item => 
                    <option key={item.Id} value={item.Id}>{`${item.Name} (${item.Id} [${item.Site.Name}])`}</option>
                )}
            </Form.Control>
        
         </Form.Group>          
            
        )      
    }
}