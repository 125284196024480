import React, {useState, useEffect, useRef} from 'react'
import './App.css';
import {Home} from './components/Home';
import {Site} from './components/site/Site';
import {Drive} from './components/drive/Drive';
import {Worker} from './components/worker/Worker';
import {Detail} from './components/detail/Detail';
import {Step} from './components/step/Step';
import {Route, Routes} from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Landing/Footer';
import Global from './components/Global';
import Landing from './components/Landing/Landing';
import Profile from './components/Profile/Profile';

const global = new Global()

function App() {
  const app = 'Synchronize'
  const [connected, Connect] = useState(false)
  const [search, Search]  = useState("")
  const route = useRef("/")
  const [role, Role] = useState(2)
  const [infos, Infos] = useState([])

  useEffect(() => {
    Connect(global.connected())
/*
    const store = localStorage.getItem(app)
    if(store) {
        const value = JSON.parse(store)
        Connect(value?.connected)
        Infos(value?.message?.split('-'))
    }
*/
  },[])
  
  return (

    connected ?

    <>

    <Header app={app} connected={connected} onSearch={Search} />

    <div className='container'>

      <Routes>    
        <Route path='/' element={ <Home app={app} global={global} search={search} />} exact />
        <Route path='/site' element={ <Site global={global} search={search} />} />
        <Route path='/drive' element={ <Drive global={global} search={search} />} />
        <Route path='/worker' element={ <Worker global={global} search={search} />} />
        <Route path='/detail' element={ <Detail global={global} search={search} />} />
        <Route path='/step' element={ <Step global={global} />} />
        <Route path='/profile' element={ <Profile  connected={connected} />} />
      </Routes>
      
</div>
    <Footer/>

    </>
:
    <Landing/>

  )
}

export default App;


/*
 {  global.connected() ? 
  <Navigate to='/drive' />
: 
  <Navigate to='/' />
}

 */