import React from 'react'

export default function  CheckControl(box = {name:"Checked", value:false, mode:0}) {
    const [checked, setChecked] = React.useState(box.value);
  
    return (
        <div class="form-group form-row">
      <label class="control-label col-sm-2" style={{width:"auto", textAlign:"right"}}>
          {box.name}: </label>
          <div class="col-sm-1">
        <input type="checkbox" name={box.name}
          defaultChecked={checked}
          onChange={() => setChecked(!checked)}
          readOnly={(box.mode??0)===0}
          class="form-control" 
        />
        
        </div>
      </div>
    )
}
