
const Config = {
    salaam :'Salaam',
    title :'sync.2us.ma',
    app :'Synchronize',
    logo :'logo.png'
}

const header = {
    joinus : "Join us",
    signin : "Login",    
    signout : "Exit"
}

const home = {
    title:'Synchronize',
    about:'Synchronize Service from Local to Cloud'
}

const blog = {
    title :'Our most interesting articles',
    read:'Read more...'
}

const path = {
    icons : 'https://2us.ma/content/icons/'
}

const icons = {
    add : path.icons+'add.png',
    reload : path.icons+'reload.png',
    minimize : path.icons+'minimize.png',
    maximize : path.icons+'maximize.png'
}

export default Config
export {header, home, blog, icons}