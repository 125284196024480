import React,{Component} from 'react'
import {Container, Table, Button, ButtonToolbar} from 'react-bootstrap'
import {DriveModal} from './DriveModal'
import { ToId, ToDate } from '../shared/ViewControl'

export class Drive extends Component{

    constructor (props){
        super(props)
        this.state = {items:[], itemId:0, modalShow:false, mode:0}
        this.entity='sync/drive'

        //console.log('Drive.global',this.props.global)
    }

    componentDidMount(){
        this.Load()
    }

    componentDidUpdate() {
        document.title = `Drive ${this.state.itemId}`;
    }

    componentWillUnmount() {
        this.setState({items:[]})        
        //document.removeEventListener("click", this.closeMenu);
    }

    Load() {
        this.props.global.GET(this.entity)
        .then(data => this.setState({items:data}))
        .catch(error => console.log({ error }))
    }    
    
    Delete(id) {
        this.props.global.DELETE(this.entity, id)
        .then(()=> this.Load())
    }

    modalClose =() => {
        this.setState({modalShow:false})
        this.Load()
    }

    render() {

        const {items}=this.state;

        const filtered = items.filter(
                (item)=>{
                return (item.Name+item.Path+item.Pattern).indexOf(this.props.search)!==-1;
        })

        return (
            <Container>
            
<Table className="mt-3" striped hover bordered size="sm">
<thead>
    <tr>
        <th>Id</th>
        <th>Site</th>
        <th>Name</th>
        <th>Path</th>
        <th>Pattern</th>
        <th>Ignore</th>
        <th style={{width:'6rem'}}>Date</th>
        <th>
            <ButtonToolbar style={{ display: 'flex', justifyContent: 'center', width:'7em' }}>
            <Button variant='primary' onClick={()=>this.setState({modalShow:true, itemId:0, mode:2})}>
            +</Button>           
            </ButtonToolbar>
        </th> 
    </tr>
</thead>

<tbody>
    {
        filtered.map(item=>
            <tr key={item.Id}>
<ToId value={item.Id} onClick={()=> this.setState({modalShow:true,itemId:item.Id,mode:0})} selelcetd={this.state.itemId}/>
<td>{item.SiteId}</td>
<td>{item.Name}</td>
<td title={item.Path}>{item.Path.substring(item.Path.length - 24)}</td>
<td>{item.Pattern}</td>
<td title={item.Ignore}>{item.Ignore.substring(0,item.Ignore.length - 16)}</td>
<ToDate value={item.Created} />
<td style={{width:'2rem'}}>
    <ButtonToolbar>
        <Button className="mr-2" variant="info" onClick={()=> this.setState({modalShow:true,itemId:item.Id,mode:1})}
        ><i className="fa fa-edit"></i></Button>

        <Button className="mr-2" variant="danger" onClick={()=> this.Delete(item.Id)} 
        ><i className="fa fa-trash"></i></Button>
    </ButtonToolbar>
</td>
            </tr>
            )
    }
</tbody>
</Table>

{this.state.modalShow ?

<DriveModal 
    global={this.props.global}
    entity={this.entity}
    id={this.state.itemId}
    mode={this.state.mode}
    show={true}
    onHide={this.modalClose}
/>
: null}

</Container>


        )
    }
}