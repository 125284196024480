import React from 'react'
import {Form} from 'react-bootstrap'


const TextControl = ({name,value,mode,type}) => {

    return (
        <Form.Group controlId={name} className='form-row'>
                <Form.Label className='col-sm-2'>{name} :</Form.Label>
                <Form.Control
                className='col-sm-10'
                type={type ?? 'text'}
                name={name}                
                placeholder={name}
                //value={value}
                defaultValue={value}                
                readOnly={mode===0}
                //  required
                />
            </Form.Group>        
    )
}

export default TextControl
