import React, {useState, useEffect} from 'react';
import {FormControl, Row, Col, Navbar} from 'react-bootstrap';
import {NavLink} from 'react-router-dom'; 
import {Navigation} from './Navigation';
import Config from './Config/en';

const Header = ({app, connected, onSearch}) => {

    const [profile, Profile] = useState('hidden')

    const onProfile = (e) => {
        //alert("onProfile")
        if(profile==='hidden') {
            const element = document.getElementById("ProfileItems")
            if(element) {
                //element.style.top = `${e.clientY+24}px`
                element.style.left = `${e.clientX - 24}px`
            }
        }
        Profile( profile==='hidden' ? 'visible' : 'hidden')
    }
  
    const onChange = (e) => {
        //alert(e.target.value)
        onSearch(e.target.value)

        //this.forceUpdate()
        //console.log('Search',e.target.value, search)
    }

    const onLogout = (e) => {
        //alert('remove localStorage')
        localStorage.removeItem(app)
        window.location.reload()
    }

    useEffect(() => {
        onSearch('')
        Profile('')
    }, [])    

    return (
        
        <div className="Header">
            <Row className="navbar navbar-custom" expand="lg" style={{justifyContent:"center", display: "flex"}}>
                <Col sm={1}>
                    <NavLink to="/" >
                        <img className="Logo" src={Config.logo} alt="Home" height="90px" padding="5px"  />
                    </NavLink>
                </Col>
                <Col  sm={6}>
                    <Navigation  connected={connected} />
                </Col>
                <Col  sm={1} style={{ textAlign: 'right' }}>
                    <i className="fa fa-search" style={{ fontSize:'1.5rem' }} onClick={onChange} ></i>
                </Col>
                <Col  sm={3}>
                    <FormControl 
                        className="d-inline p-2" 
                        style={{ textAlign: 'center', backgroundColor:'lightgray' }} 
                        name="Search" type="text" 
                        defaultValue=""
                        onChange={onChange}
                        placeholder="Search Items" />
                </Col>
                <Col  sm={1}>
                <Navbar >
                    <NavLink className="d-inline p-3 navbar-custom" to="/" style={{float:'right'}} onClick={onProfile} >Profile</NavLink>
                </Navbar>
                </Col>
            </Row>

<ul id="ProfileItems" style={{visibility:profile}}>
		<li><a href="/profile">Profile</a></li>
		<li><a href="https://2us.ma" target="_blank">Home</a></li>
        <li><a href="https://tech2us.ma" target="_blank">Tech2us</a></li>
        <li><a href="https://digitalservices.ma/apps/sync2us/publish.htm" target="_blank">SuncApp</a></li>
        <li><a href="/setting">Settings</a></li>
		<li style={{display:'block'}} onClick={onLogout} >Sign Out</li> 
</ul>

        </div>
        
    )
}

export default Header;