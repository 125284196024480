import React from 'react';
import styled from 'styled-components';

const FooterContainer = styled.footer`
  padding: 0.5rem 2rem;
  text-align: center;
  background-color: #eee; /* Light gray background */
  color: #333;
`;

const Copyright = styled.p`
  margin: 0;
`;

const Footer = () => {
  return (
    <FooterContainer>
      <Copyright>&copy; {new Date().getFullYear()} Rais Digital Services</Copyright>
    </FooterContainer>
  );
};

export default Footer;
