import React,{Component} from 'react'
import {Modal,Row,Col,Form} from 'react-bootstrap'
import {ToNotify} from '../shared/ViewControl'
import HeaderControl from '../shared/HeaderControl'
import FooterControl from '../shared/FooterControl'
import SaveControl from '../shared/SaveControl'
import TextControl from '../shared/TextControl'

export class DetailModal extends Component {
    constructor(props){
        super(props)

        this.state = {snackbaropen:false, snackbarmsg:'', Item:null, loading:false}
        this.handleSubmit = this.handleSubmit.bind(this)
    }

componentDidMount(){
    if(this.props.show) {
        if( this.props.id>0) {
            this.setState({loading:true})
            this.props.global.GetItem(this.props.entity, this.props.id)
            .then(data =>  {
                    this.setState({Item:data, loading:false})
                    //console.log('ItemModal', this.state.Item, this.state.loading)
                }
            )
            .catch(error => console.log({ error }))
        }
    }
}

    snackbarClose = (event) => {
        this.setState({snackbaropen:false})
        this.props.onHide()
    }

    handleSubmit(event){
        if(this.props.mode>0) {
            event.preventDefault()

    fetch(`${this.props.global.apiUrl+this.props.entity}`,{
        method:(this.props.mode===2)?'POST':'PUT',
        headers:this.props.global.headers,
        body:JSON.stringify({
        Id: this.props.id,
        WorkerId:event.target.WorkerId.value,
        File:event.target.Name.value,
        Size:event.target.Size.value,
        Modified:event.target.Modified.value,
        StatusId:event.target.StatusId.value,
        account:this.props.global.store.account
        })
    })
    .then(response => response.json())
            .then(
                (result)=>{
                    this.setState({snackbaropen:true, snackbarmsg:result.message})
                },
                (error)=>{
                    this.setState({snackbaropen:true, snackbarmsg:error.message})
                }
            )
        }
    }
    
    render() {
        let { Item, loading } = this.state; 
        return(
            <div className="container">

<ToNotify
message={this.state.snackbarmsg}
onClose={this.snackbarClose}
/>
        <Modal
            {...this.props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            animation={false}
            centered
            >
            <HeaderControl {...this.props} />

            <Modal.Body>

<Row>
    <Col sm={8} >
    {
        loading ? <div>Item is loading...</div> :
         <Form onSubmit={this.handleSubmit}>
            <TextControl name="WorkerId" value={Item ? Item.WorkerId:''} mode={this.props.mode} required />
            <TextControl name="File" value={Item ? Item.File:''} mode={this.props.mode} required />
            <TextControl name="Size" value={Item ? Item.Size:''} mode={this.props.mode} />
            <TextControl name="Modified" value={Item ? Item.Modified:''} mode={this.props.mode} />
            <TextControl name="StatusId" value={Item ? Item.StatusId:''} mode={this.props.mode} />

            <SaveControl {...this.props} />

         </Form>
    }
    </Col>
</Row>
            </Modal.Body>

            <FooterControl {...this.props} />
        </Modal>
</div>  
        )
    }
}
