import React from 'react'
import {Modal, Button} from 'react-bootstrap';

const FooterControl = ({entity, onHide}) => {

    return (
        <Modal.Footer>
            <Button variant="danger" size="sm" onClick={onHide}><i className="fa fa-close"></i></Button>
        </Modal.Footer>
    )
}

export default FooterControl
