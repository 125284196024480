import React,{Component} from 'react'
import {Container, Table, Button, ButtonToolbar} from 'react-bootstrap'
import {SiteModal} from './SiteModal'
import { ToId, ToDate } from '../shared/ViewControl'
import {meResults} from '../shared/meLayout'

export class Site extends Component{

    constructor (props){
        super(props)
        this.state = {items:[], itemId:0, modalShow:false, mode:0}
        this.route='sync/site'
        this.entity='Sites'
        this.resizer = new  meResults(this.entity)
    }

    componentDidMount(){
        //alert('Site...')
        this.Load()
    }
    
    componentWillUnmount() {
        this.setState({items:[]})        
        //document.removeEventListener("click", this.closeMenu);
    }

    Load() {
        this.props.global.GET(this.route)
        .then(data => {
            this.setState({items:data})

            this.resizer.selectorAll()
            //alert(`resizer ${this.resizer.items.length}`)
            
        })
        .catch(error => console.log({ error }))
    }    
    
    Delete(id) {
        this.props.global.DELETE(this.route, id)
        .then(()=> this.Load())
    }

    modalClose =() => {
        this.setState({modalShow:false})
        this.Load()
    }

    onMouseUp = (event) => {
        this.resizer.mouseUp(event)
      }
    
    render() {

//  let modalClose =() => this.setState({modalShow:false});
        const filtered = this.state.items.filter(
            (item)=>{
            return (item.Name+' '+item.Description+' '+item.Host+' '+item.User).indexOf(this.props.search)!==-1;
        })


        return (
            <Container>

<Table id={this.entity+"Table"} className="mt-3" striped hover bordered size="sm">
<thead id={this.entity+"Head"}
            onMouseDown={(e)=>{this.resizer.mouseDown(e)}} 
            onMouseMove={(e)=>{this.resizer.mouseMove(e)}} 
            onMouseUp={this.onMouseUp}     
>
    <tr>
        <th>Id</th>
        <th>Account</th>
        <th>Name</th>
        <th>Description</th>
        <th>Host</th>
        <th>Port</th>
        <th>User</th>
        <th>Position</th>        
        <th>Ssl</th>
        <th>Ssh</th>
        <th>Date</th>
        <th>
            <ButtonToolbar style={{ display: 'flex', justifyContent: 'center' }}>
            <Button variant='primary' onClick={()=>this.setState({modalShow:true, itemId:0, mode:2})}>+</Button>
            </ButtonToolbar>
        </th> 
    </tr>
</thead>

<tbody id={this.entity+"Body"}>
    {
        filtered.map(item=>
            <tr key={item.Id}>
<ToId value={item.Id} onClick={()=> this.setState({modalShow:true,itemId:item.Id,mode:0})} selelcetd={this.state.itemId}/>
<td>{item.AccountId}</td>
<td>{item.Name}</td>
<td>{item.Description}</td>
<td>{item.Host}</td>
<td>{item.Port}</td>
<td>{item.User}</td>
<td>{item.Position}</td>
<td>{item.Ssl.toString()}</td>
<td>{item.Ssh.toString()}</td>
<ToDate value={item.Created} />
<td style={{width:'2rem'}}>
    <ButtonToolbar>
        <Button className="mr-2" variant="info"
        onClick={()=> this.setState({modalShow:true,itemId:item.Id,mode:1})}
        ><i className="fa fa-edit"></i></Button>

    </ButtonToolbar>
</td>
            </tr>
            )
    }
</tbody>
</Table>

{this.state.modalShow ?

<SiteModal 
    global={this.props.global}
    entity={this.route}
    id={this.state.itemId}
    mode={this.state.mode}
    show={true}
    onHide={this.modalClose}
/>
: null}

</Container>
        )
    }
}

export default Site;