import React from 'react'
import {Modal,Row,Col,Form} from 'react-bootstrap'
import CheckControl from '../shared/CheckControl'
import TextControl from '../shared/TextControl'
import {ToNotify} from '../shared/ViewControl'
import HeaderControl from '../shared/HeaderControl'
import FooterControl from '../shared/FooterControl'
import SaveControl from '../shared/SaveControl'

export class SiteModal extends React.Component {
    constructor(props){
        super(props)

        this.state = {snackbaropen:false, snackbarmsg:'', Item:null, loading:false}
        this.handleSubmit = this.handleSubmit.bind(this)
    }

    componentDidMount(){
        if(this.props.show) {
            if(this.props.id>0) {
                this.setState({loading:true})
                this.props.global.GetItem(this.props.entity,this.props.id)
                .then((data) =>  {
                        this.setState({Item:data, loading:false})
                        //console.log('ItemModal', this.state.Item)
                        //alert(Item.Position)
                    }
                )
                .catch(error => console.log({ error }))
            }
        }
    }

    snackbarClose = (event) => {
        this.setState({snackbaropen:false})
        this.props.onHide()
    }

    handleSubmit(event){
        event.preventDefault();

        let newData = JSON.stringify({
            Id:(this.props.mode===2)?0:this.state.Item.Id,
            AccountId:1,
            Name:event.target.Name.value,
            Description:event.target.Description.value,
            Host:event.target.Host.value,
            Port:event.target.Port.value,
            User:event.target.User.value,
            Password:event.target.Password.value,
            Position:event.target.Position.value,
            Ssl:(event.target.Ssl.checked),
            Ssh:(event.target.Ssh.checked)
        })

        console.log('newData', newData)

        fetch(`${this.props.global.apiUrl+this.props.entity}`,{
            method:(this.props.mode===2)?'POST':'PUT',
headers:this.props.global.headers,
body: newData
})
.then(response => response.json())
        .then(
            (result)=>{
                this.setState({snackbaropen:true, snackbarmsg:result.message})
            },
            (error)=>{
                this.setState({snackbaropen:true, snackbarmsg:error.message})
            }
        )
    }


    
    render() {
        let { Item } = this.state; 
        return(
            <div className="container">

<ToNotify 
message={this.state.snackbarmsg}
onClose={this.snackbarClose}
/>
        <Modal
            {...this.props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            animation={false}
            centered
        >
<HeaderControl {...this.props} />

            <Modal.Body>

 <Row>
     <Col sm={10} >
     {
            (this.state.loading || (Item===null && this.props.mode===1)) ?
             <div><i class="fa fa-spinner fa-pulse fa-3x fa-fw"></i> loading...</div> :
         <Form class="form-horizontal" onSubmit={this.handleSubmit}>

<TextControl name="Name" value={Item ? Item.Name:''} mode={this.props.mode} required />
<div class="form-group">
  <label for="comment">Description:</label>
  <textarea class="form-control" rows="3" name="Description" defaultValue={Item ? Item.Description:''}></textarea>
</div>
<TextControl name="Host" value={Item ? Item.Host:''} mode={this.props.mode} />
<TextControl name="Port" value={Item ? Item.Port:'21'} mode={this.props.mode} />
<TextControl name="User" value={Item ? Item.User:''} mode={this.props.mode} />
<TextControl name="Password" value={Item ? Item.Password:''} mode={this.props.mode} type="password" />
<TextControl name="Position" value={Item ? Item.Position:''} mode={this.props.mode} />
<CheckControl name="Ssl" value={Item ? Item.Ssl:false} mode={this.props.mode} />
<CheckControl name="Ssh" value={Item ? Item.Ssh:false} mode={this.props.mode} />

<SaveControl {...this.props} />

         </Form>
         }
     </Col>
 </Row>

            </Modal.Body>

            <FooterControl {...this.props} />
            
          </Modal>
</div>  
        )
    }
}
