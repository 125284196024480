import React, { Component } from 'react';
import {NavLink} from 'react-router-dom'; 
import {Navbar, Container} from 'react-bootstrap'; 

export class Navigation extends Component {


    render(){

        return (
            
            <Container>
{ this.props.connected ?
            <Navbar>
                <Navbar.Collapse id="basic-navbar-nav" >
                    <NavLink className="d-inline p-3 navbar-custom" to="/site">Site</NavLink>
                    <NavLink className="d-inline p-3 navbar-custom" to="/drive">Drive</NavLink>
                    <NavLink className="d-inline p-3 navbar-custom" to="/worker">Worker</NavLink>
                    <NavLink className="d-inline p-3 navbar-custom" to="/detail">Detail</NavLink>
                    <NavLink className="d-inline p-3 navbar-custom" to="/step">Step</NavLink>                  
                </Navbar.Collapse>
            </Navbar>
: null }

            </Container>

        )
    }
}