// Hero.js (example)
import React, { useState, useEffect } from 'react';
import { animated, useSpring } from 'react-spring'; // Animation library
import styled from 'styled-components';
import {home} from '../Config/en'

const Home = () => {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
//    const trigger = useScrollTrigger({ onEnter: () => setIsScrolled(true), onLeave: () => setIsScrolled(false) });
//    return () => trigger.cleanup();
  }, []);

  const heroAnimation = useSpring({
    from: { opacity: 0 },
    to: { opacity: isScrolled ? 1 : 0 },
    config: { duration: 1000 },
  });

  return (
    <HomeContainer style={heroAnimation} id="about">
      <p className='brillant'>{home.about}</p>
    </HomeContainer>
  );
};


const HomeContainer = styled.div`
max-width: 1024px;
margin: auto;
  background-image: url('landing.jpg');
  background-size: cover; /* contain; */
  background-position: center;
  height: 90vh;
  opacity: 0.9;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-repeat: no-repeat;
  background-color: rgb(242,251,254);
  align-items: center;
  color: #fff;
`;

export default Home;

