// ModernSlider.jsx
import React, { useState, useEffect } from 'react';
import './Slider.css';

const Slider = ({ slides = [
  {
    image: "https://2us.ma/images/Sync-1.jpeg",
    title: "Synchronize Service",
    description: "Synchronize files from Local to Cloud and helps to organize your data and work !"
  },
  {
    image: "https://2us.ma/images/Sync-2.jpeg",
    title: "Transfer Data",
    description: "Sync services transfers data from a local device to a cloud storage solution"
  },
  {
    image: "https://2us.ma/images/2us.ma.jpeg",
    title: "Tech2us",
    description: "Tech2us platform helps everyone to use tech better to make life easier!"
  },
  {
    image: "https://2us.ma/images/2us.ma-2.jpeg",
    title: "Tech2us",
    description: "Technology not only enhances convenience but also increases accessibility"
  }
] }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isAutoPlaying, setIsAutoPlaying] = useState(true);

  useEffect(() => {
    let interval;
    if (isAutoPlaying) {
      interval = setInterval(() => {
        setCurrentSlide((prev) => (prev + 1) % slides.length);
      }, 6000);
    }
    return () => clearInterval(interval);
  }, [isAutoPlaying, slides.length]);

  const nextSlide = () => {
    setCurrentSlide((prev) => (prev + 1) % slides.length);
  };

  const prevSlide = () => {
    setCurrentSlide((prev) => (prev - 1 + slides.length) % slides.length);
  };

  const goToSlide = (index) => {
    setCurrentSlide(index);
  };

  return (
    <div className="slider-container">
      <div 
        className="slider-track"
        style={{ transform: `translateX(-${currentSlide * 100}%)` }}
      >
        {slides.map((slide, index) => (
          <div key={index} className="slide">
            <img
              src={slide.image}
              alt={slide.title}
            />
            <div className="slide-content">
              <h2>{slide.title}</h2>
              <p>{slide.description}</p>
            </div>
          </div>
        ))}
      </div>

      <button
        onClick={prevSlide}
        className="nav-button prev-button"
        aria-label="Previous slide"
      >
        ‹
      </button>
      <button
        onClick={nextSlide}
        className="nav-button next-button"
        aria-label="Next slide"
      >
        ›
      </button>

      <div className="dots-container">
        {slides.map((_, index) => (
          <button
            key={index}
            onClick={() => goToSlide(index)}
            className={`dot ${currentSlide === index ? 'active' : ''}`}
            aria-label={`Go to slide ${index + 1}`}
          />
        ))}
      </div>

      <button
        onClick={() => setIsAutoPlaying(!isAutoPlaying)}
        className="autoplay-button"
        aria-label={isAutoPlaying ? "Pause autoplay" : "Start autoplay"}
      >
        {isAutoPlaying ? "⏸" : "▶"}
      </button>
    </div>
  );
};

export default Slider;