import axios from 'axios';

const token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJiaXNtaSI6Itio2ZDYs9uh2YXZkCDZsdmE2YTZh9mQINmx2YTYsdmR2Y7Yrduh2YXZjtmA2bDZhtmQINmx2YTYsdmR2Y7YrdmQ2YrZhdmQIiwiYWxhYW4iOiIxNCDYsdmF2LbYp9mGIDE0NDUiLCJpYXQiOjE3MTEzMzAyNDF9.m6pXkmbB6H0czA1E-7knprJ_lbo4AKB67MWe93024fY"

const Api = axios.create({

  baseURL: `https://api.akademy.ma`, 

  headers : {
    'Authorization': `Bearer ${token}`,       
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  }
})

const Read = (request, Items, Load, Message) => {
  //alert(request)
  Items([])
  Api.get(request)
  .then(response => {
      //console.log('Moshaf.Get', request, response.data); alert('Moshaf.Load '+request)
      Items(response.data)
      Load && Load(true)
  })
  .catch(error => {
      //if (error.status && error.data)
      Message && Message(error)
      console.log('Error',request,error)
  })
}

const Update = (id, field, value, Message) => {

  if(id && id>0 && field && value) {
    let query=`/${id}/${field}/${value}`
    //alert(query)
    Api.put(query)
    .then(response => {
      let rows=response.data.affectedRows
      Message && Message(`${rows} updated`)
      //console.log('Moshaf.onUpdate', response.status, response.data.affectedRows)
    })
    .catch(error => {
      Message && Message(error)
      console.error(error)
    })
  }
}

async function Fetch(request, data, object=null, method='POST') {

	if(!request.startsWith('https'))    
		request = Api.defaults.baseURL+request
  
	return new Promise(function (resolve, reject) {
		//alert(`${request}  ${data.Entity}`)
		fetch(request, {
			method: method,  
			headers: new Headers({
				'Authorization': Api.defaults.headers.Authorization,
				'Content-Type': "application/json"
			})
			,body: data && JSON.stringify(data) 
		}) 
		.then( async (response) => {
			if( [200,201,202].includes(response.status)) { 
				object && (object.style.borderColor ='green')
				resolve(response)
			}
			else {
        //console.log("Response", request, method, data, response); alert(`Response ${response.status}`)
				object && (object.style.borderColor = 'orange')
			}
		})
		.catch((error) => {
			//console.log("Error", error, data)
			object && (object.style.borderColor = 'red')
			//reject(error)			
		})
	})
}

export default Api
export {Read, Update, Fetch}
