import React from 'react';
import styled from 'styled-components';

const ActionContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  /*padding: 2rem 0;*/
  background-color: #f0f0f0; /* Light gray background */
`;

const ActionButton = styled.a`
  background-color: #007bff; /* Blue button color */
  color: #fff; /* White button text */
  padding: 0.7rem 2rem;
  border: none;
  border-radius: 5px;
  font-size: 1.2rem;
  cursor: pointer;
  text-decoration: none;
  target: _blank;
  rel : noopener noreferrer;
  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: #0069d9; /* Darker blue on hover */
  }
`;

const CallToAction = ({name='Sign in', onClick}) => {
  return (
    name.startsWith('Join') ?
    <ActionContainer  id="contact">
      <ActionButton href="mailto:contact@tech2us.ma?subject=I want to join you&body=Salaam, Thank you for accepting my membership request!">
      {name}
      </ActionButton>
    </ActionContainer>
    : 
    <ActionContainer  id="connexion">
    <ActionButton onClick={onClick}>
      {name}
    </ActionButton>
  </ActionContainer>

  );
};

export default CallToAction;
