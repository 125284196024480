import React,{Component} from 'react'
import {Table} from 'react-bootstrap'

export class Step extends Component{

    constructor (props){
        super(props)
        this.state = {items:[]}
        this.entity='sync/step'
    }

    componentDidMount(){
        this.Load()
    }

    Load() {        
        this.props.global.GET(this.entity)
        .then(data => this.setState({items:data}))        
    }    

    render() {


        return (
            <div className="mt-5 d-flex justify-content-left">

            
<Table className="mt-4" striped hover bordered size="sm">
<thead>
    <tr>
        <th>Id</th>
        <th>Name</th>
        <th>Description</th>
    </tr>
</thead>

<tbody>
    {
        this.state.items.map(item=>
            <tr key={item.Id}>

<td>{item.Id}</td>
<td>{item.Name}</td>
<td>{item.Description}</td>
            </tr>
            )
    }
</tbody>
</Table>

</div>


        )
    }
}