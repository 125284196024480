// App.js
import React from 'react';
import './Landing.css';
import Header from './Header';
import Articles from './Articles';
import CallToAction from '../shared/CallToAction';
import Footer from './Footer';
import Home from './Home';
import Slider from './Slider';

function Landing() {
  return (
    <div className="App">
      <Header />
      <Slider />
      <Articles />
      <CallToAction />
      <Footer />
    </div>
  );
}

export default Landing;

// <Home />
// <Slider />