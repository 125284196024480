import React from 'react'
import {Modal} from 'react-bootstrap';

const HeaderControl = ({entity,mode,id}) => {

    return (
        <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">            
            {
                mode===1 ? <h3>Edit ({entity}.Id={id})</h3>:<h3>{entity}</h3>
            }
            </Modal.Title>
        </Modal.Header>
    )
}

export default HeaderControl
