import React,{Component} from 'react'
import {Modal,Button,Row,Col,Form, ButtonToolbar} from 'react-bootstrap'
import CheckControl from '../shared/CheckControl'
import TextControl from '../shared/TextControl'
import HeaderControl from '../shared/HeaderControl'
import {ToNotify} from '../shared/ViewControl'
import SaveControl from '../shared/SaveControl'
import {DriveControl} from '../drive/DriveControl'
import {DriveModal} from '../drive/DriveModal'

export class WorkerModal extends Component {
    constructor(props){
        super(props)
        this.state = {snackbaropen:false, snackbarmsg:'', Item:null, loading:false, modalShow:false}
        this.handleSubmit = this.handleSubmit.bind(this)
    }

componentDidMount(){
    if(this.props.show) {
        if(this.props.id>0) {
            this.setState({loading:true})
            this.props.global.GetItem(this.props.entity,this.props.id)
            .then(data =>  {
                    this.setState({Item:data, loading:false})
                    //console.log('ItemModal', this.state.Item, this.state.loading)
                }
            )
            .catch(error => console.log({ error }))
        }
    }
}

    snackbarClose = (event) => {
        this.setState({snackbaropen:false})
        this.props.onHide()
    }

    handleSubmit(event){
        if(this.props.mode>0) {
            event.preventDefault();

            let newData = JSON.stringify({
                Id:(this.props.mode===2)?0:this.state.Item.Id,
                Name:event.target.Name.value,
                Description:event.target.Description.value,
                DriveIn:event.target.DriveIn.value,
                DriveOut:event.target.DriveOut.value,
                Priority:event.target.Priority.value,
                Option:event.target.Option.value,
                Active:(event.target.Active.checked),
                account:this.props.global.store.account
            })
            //console.log('newData', newData)

    fetch(`${this.props.global.apiUrl+this.props.entity}`,{
        method:(this.props.mode===2)?'POST':'PUT',
        headers:this.props.global.headers,
        body:newData
    })
    .then(response => response.json())
            .then(
                (result)=>{
                    this.setState({snackbaropen:true, snackbarmsg:result.message})

                    //setTimeout(function () { }, 5000);
                    
                    //this.props.onHide()
                },
                (error)=>{
                    this.setState({snackbaropen:true, snackbarmsg:error.message});
                }
            )
        }
    }
    
    modalClose =() => {
        this.setState({modalShow:false})
        this.forceUpdate()
    }

    render() {
        let { Item } = this.state; 

        return(
            <div className="container">
<ToNotify 
message={this.state.snackbarmsg}
onClose={this.snackbarClose}
/>
            <Modal
            {...this.props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            animation={false}
            centered
          >
            
            <HeaderControl {...this.props} />
            
            <Modal.Body>

 <Row>
     <Col sm={8} >
     {
            this.state.loading ? <div>Item is loading...</div> :
<Form class="form-horizontal" onSubmit={this.handleSubmit}>

<TextControl name="Name" value={Item ? Item.Name:''} mode={this.props.mode} required />
<div class="form-group">  <label for="comment">Description:</label>
  <textarea class="form-control" rows="3" name="Description" defaultValue={Item ? Item.Description:''}></textarea>
</div>
<DriveControl name='DriveIn' request={this.props.global.apiUrl+'setting/drives'} headers={this.props.global.headers} id={Item ? Item.DriveIn:1} readOnly={this.props.mode===0} label='From Drive'/> 
<DriveControl name='DriveOut' request={this.props.global.apiUrl+'setting/drives'} headers={this.props.global.headers} id={Item ? Item.DriveOut:1} readOnly={this.props.mode===0} label='To Drive'/> 

<TextControl name="Priority" value={Item ? Item.Priority:''} mode={this.props.mode} />
<TextControl name="Option" value={Item ? Item.Option:''} mode={this.props.mode} />
<CheckControl name="Active" value={Item ? Item.Active : true} mode={this.props.mode} />

<SaveControl {...this.props} />

         </Form>
         }
     </Col>
     <Col>
        <ButtonToolbar style={{ display: 'flex', justifyContent: 'center' }}>
            <Button variant='primary' onClick={()=>this.setState({modalShow:true})}><i className="fa fa-plus"></i> Drive</Button>
        </ButtonToolbar>
     </Col>
 </Row>

            </Modal.Body>
            <Modal.Footer>
              <Button variant="danger" size="sm" onClick={this.props.onHide}><i className="fa fa-close"></i></Button>
            </Modal.Footer>
          </Modal>

          {this.state.modalShow ?
<DriveModal 
    global={this.props.global}
    entity={'sync/drive'}
    id={0}
    mode={2}
    show={true}
    onHide={this.modalClose}
/>
: null}

</div>  
        )
    }
}
